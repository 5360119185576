<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    ref="multipleTable"
    tooltip-effect="dark"
    style="width: 100%"
    @selection-change="handleSelectionChange"
  >
    <el-table-column
      type="selection"
      width="55"
      :selectable="selectAbleFirst"
    ></el-table-column>

    <el-table-column
      prop="supplier_order_no"
      align="center"
      label="供货单号"
      min-width="100"
      show-overflow-tooltip
    >
    </el-table-column>

    <el-table-column
      prop="supply_date"
      align="center"
      label="供货日期"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="product_title"
      align="center"
      label="品名"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        {{ scope.row.product_title || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="category_name"
      align="center"
      label="品类"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="sku_code"
      align="center"
      label="货号"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="buyer_name"
      align="center"
      label="业务员"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.buyer_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="logistics_name"
      align="center"
      label="城市仓"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.logistics_name || "-" }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="delivery_name"
      align="center"
      label="自提点"
      min-width="100"
      show-overflow-tooltip
    >
      <template slot-scope="scope">
        <span>{{ scope.row.delivery_name || "-" }}</span>
      </template>
    </el-table-column>

    <el-table-column
      prop="audit_status_txt"
      align="center"
      label="品控状态"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="review_user_name"
      align="center"
      label="品控员"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.review_user_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="qc_user_name"
      align="center"
      label="复核员"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.qc_user_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="supplier_name"
      align="center"
      label="供应商"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.supplier_name || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="origin_number"
      align="center"
      label="订货数量"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="review_number"
      align="center"
      label="复核数量"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="stockout_number"
      align="center"
      label="缺货数量"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="qc_reason_txt"
      align="center"
      label="缺货原因"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.qc_reason_txt || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="head_type_txt"
      align="center"
      label="责任方"
      min-width="100"
    >
      <template slot-scope="scope">
        {{ scope.row.head_type_txt || "-" }}
      </template>
    </el-table-column>

    <el-table-column
      prop="deduction_amt"
      align="center"
      label="扣款金额"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="deduction_time"
      align="center"
      label="扣款时间"
      min-width="100"
    >
    </el-table-column>
    <el-table-column
      prop="is_deduct_txt"
      align="center"
      label="扣款状态"
      min-width="100"
    >
    </el-table-column>

    <el-table-column
      prop="is_coupon_txt"
      align="center"
      label="发券状态"
      min-width="100"
    >
    </el-table-column>

    <el-table-column label="操作" min-width="120" align="center">
      <template slot-scope="scope">
        <el-button
          @click="onHandleEdit(scope.row)"
          v-if="scope.row.is_deduct === DEDUCT_STATUS.NO.value"
          type="text"
          size="small"
          >编辑</el-button
        >
        <span v-else>-</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { DEDUCT_STATUS, HEAD_TYPE_ENUM } from "../../utils/enum/index";

export default {
  name: "TableList",
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      DEDUCT_STATUS,
      multipleSelection: [], // 当前勾选的数据列
    };
  },
  methods: {
    /**
     * 清空选择
     */
    onClearSelection() {
      this.$refs.multipleTable.clearSelection();
    },
    /**
     * 当前行是否可勾选
     * 已指定责任方
     */
    selectAbleFirst(row) {
      return row.head_type !== HEAD_TYPE_ENUM.DEFAULT.value;
    },
    /**
     * 勾选当前数据
     */
    handleSelectionChange(data) {
      this.multipleSelection = data;
      this.$emit("on-select", data);
    },
    /**
     * 编辑
     */
    onHandleEdit(row) {
      this.$emit("on-edit", row);
    },
  },
};
</script>

<style lang="scss" scoped></style>
