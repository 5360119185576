<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
      </div>

      <div class="form-item">
        <el-date-picker
          type="date"
          v-model="formData.date"
          @change="changeDate"
          placeholder="供货日期"
          format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          :clearable="false"
          align="right"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="扣款时间起"
          end-placeholder="扣款时间止"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          align="right"
          v-model="deductionTime"
          @change="changeDeductionTime"
        >
        </el-date-picker>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.supplier_order_no"
          clearable
          placeholder="供货单号"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.supplier_name"
          clearable
          placeholder="供应商"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input v-model="formData.buyer_name" clearable placeholder="业务员">
        </el-input>
      </div>

      <div class="form-item">
        <el-select
          v-model="formData.logistic_business_id"
          filterable
          clearable
          placeholder="集配中心"
        >
          <el-option
            v-for="item of logisticList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>

      <div class="form-item">
        <!-- :filter-method="(data) => data && ajaxGetCityStoreList(data)"
        @visible-change="(data) => !data && ajaxGetCityStoreList" -->
        <el-select
          v-model="formData.logistics_id"
          @change="onChangeCityStore"
          :loading="loadingSelfLogisticsList"
          filterable
          clearable
          placeholder="城市仓"
        >
          <el-option
            v-for="item in cityStoreList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          placeholder="自提点"
          v-model="formData.delivery_id"
          :loading="loadingSelfTakeList"
          clearable
        >
          <el-option
            v-for="item in selfTakeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-input
          v-model="formData.product_title"
          clearable
          placeholder="商品名"
        >
        </el-input>
      </div>

      <div class="form-item">
        <el-input v-model="formData.c_name" clearable placeholder="品类">
        </el-input>
      </div>

      <div class="form-item">
        <el-input v-model="formData.sku_code" clearable placeholder="货号">
        </el-input>
      </div>

      <div class="form-item">
        <el-select
          popper-class="select-popper-wrap"
          v-model="formData.qc_reason"
          clearable
          placeholder="缺货原因"
        >
          <!-- v-show="!explainHideStatus.includes(item.id)" -->
          <el-option
            v-for="item in explainList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <div :title="item.name" class="content">
              {{ item.name }}
            </div>
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          placeholder="扣款状态"
          v-model="formData.is_deduct"
          clearable
        >
          <el-option
            v-for="item in deductStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item">
        <el-select
          placeholder="是否发券"
          v-model="formData.is_coupon"
          clearable
        >
          <el-option
            v-for="item in couponStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div class="form-item btn-wrap">
        <el-button @click="onHandleSearch" type="primary" icon="el-icon-search"
          >查询</el-button
        >
        <el-button
          :disabled="!tableData.length || disabledExport"
          @click="onHandleExport"
          type="warning"
          icon="el-icon-download"
          >导出</el-button
        >

        <el-button
          @click="onHandleDeduct"
          class="deduct"
          :class="{ disabled: disabledDeduct }"
          >扣款</el-button
        >

        <el-button
          @click="onHandleCoupon"
          :loading="loadingCoupon"
          class="coupon"
          :class="{ disabled: disabledCoupon }"
          >发券</el-button
        >
      </div>
    </section>
    <!-- 顶部操作 end -->

    <section class="content">
      <!-- 列表 start -->
      <table-list
        ref="refTableList"
        v-loading="loadingData"
        :tableData="tableData"
        @on-select="onChangeSelect"
        @on-edit="onHandleEdit"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="pagination">
      <el-pagination
        type="primary"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        :total="total"
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        background
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 扣款操作，弹窗 start -->
    <deduct-dialog
      ref="refDeductDialog"
      @on-change="onHandleSearch"
    ></deduct-dialog>
    <!-- 扣款操作，弹窗 end -->

    <!-- 编辑，弹窗 start -->
    <edit-dialog ref="refEditDialog" @on-change="onHandleSearch"></edit-dialog>
    <!-- 编辑，弹窗 end -->
  </section>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import utilsTool from "@/utils/tools/tool";
import { formatDate } from "@/utils/tools/date.js";
import TableList from "./modules/table-list/index.vue";
import {
  getQcRefundLackList,
  getQcRefundVerifyCoupon,
  postQcRefundSendCoupon,
} from "@/api/general/operation-center/index";
import { postAfterSaleQcExport } from "@/api/export/center";
import DeductDialog from "./modules/deduct-dialog/index.vue";
import EditDialog from "./modules/edit-dialog/index.vue";
import {
  STOCK_OUT_REASON_SELECT,
  DEDUCT_STATUS,
  COUPON_STATUS,
  // HEAD_TYPE_ENUM,
} from "./utils/enum/index";
// import mock from "./mock.js";

export default {
  name: "ReviewStockoutManage",
  components: {
    TableList,
    DeductDialog,
    EditDialog,
  },
  data() {
    return {
      deductionTime: "", // 时间对象
      loadingData: false,
      loadingSelfLogisticsList: false,
      loadingSelfTakeList: false,
      disabledExport: false,
      loadingCoupon: false,
      utilsTool,
      logisticList: [], // 集配中心列表
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      deductStatusList: Object.values(DEDUCT_STATUS), // 扣款状态下拉
      couponStatusList: Object.values(COUPON_STATUS), // 发券状态下拉
      explainList: STOCK_OUT_REASON_SELECT, // 缺货原因
      formData: {
        date: formatDate(), // 供货日期
        supplier_order_no: "", // 供货单号
        supplier_name: "", // 供应商
        buyer_name: "", // 业务员
        logistic_business_id: "", // 集配中心id
        logistics_id: "", // 城市仓id
        delivery_id: "", // 自提点ID
        product_title: "", // 商品名
        c_name: "", // 品类
        sku_code: "", // 货号
        qc_reason: "", // 缺货原因
        is_deduct: "", // 扣款状态
        is_coupon: "", // 是否发券
        page: 1,
        pageSize: 100,
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      }, // 时间范围
      formDataClone: {}, // 复制一份，用于初始化
      tableData: [],
      total: 0,
      selectList: [], // 当前选择的数据项
    };
  },
  computed: {
    /**
     * 扣款禁用逻辑
     * 1. 已指定责任方
     * 2. 且为未扣款
     */
    disabledDeduct() {
      let res = true;
      const findData = this.selectList.filter((child) => {
        // 指定责任方
        // const first = child.head_type !== HEAD_TYPE_ENUM.DEFAULT.value;
        // 未扣款
        const second = child.is_deduct === DEDUCT_STATUS.NO.value;
        // return first && second;
        return second;
      });
      res = !(findData?.length && findData?.length === this.selectList.length);
      return res;
    },
    /**
     * 发券禁用逻辑
     * 1. 已指定责任方
     * 2. 且为已扣款未发券
     */
    disabledCoupon() {
      let res = true;
      const findData = this.selectList.filter((child) => {
        // 指定责任方
        // const first = child.head_type !== HEAD_TYPE_ENUM.DEFAULT.value;
        // 已扣款未发券
        const second =
          child.is_deduct === DEDUCT_STATUS.YES.value &&
          child.is_coupon === COUPON_STATUS.NO.value;
        // return first && second;
        return second;
      });
      res = !(findData?.length && findData?.length === this.selectList.length);
      return res;
    },
  },
  watch: {
    /**
     * 如果集配中心发生改变，则重置城市仓数据
     */
    "formData.logistic_business_id"(val) {
      this.cityStoreList = [];
      this.ajaxGetCityStoreList(val);
    },
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    /**
     * 日期发生了变化
     */
    changeDeductionTime(val) {
      if (!val) {
        this.deductionTime = "";
      }
    },
    /**
     * 初始化数据
     */
    onInitData() {
      this.formDataClone = cloneDeep(this.formData);
      this.ajaxGetTableData();
      this.ajaxGetLogisticList();
      this.ajaxGetCityStoreList();
      // this.getAjaxExplainList();
    },
    /**
     * 获取集配中心列表
     */
    async ajaxGetLogisticList() {
      try {
        const { data } = await this.$api.general.businessList();
        this.logisticList = data;
      } catch (err) {
        console.log("ajax ajaxGetLogisticList err", err);
      }
    },
    /**
     * 获取城市仓列表
     */
    ajaxGetCityStoreList: debounce(async function (data) {
      this.loadingSelfLogisticsList = true;
      const params = {
        name: data,
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.logisticsList(params);
        this.cityStoreList = data.data;
        console.log("ajx ajaxGetCityStoreList", data.data);
      } catch (err) {
        console.log("ajx ajaxGetCityStoreList err", err);
      } finally {
        this.loadingSelfLogisticsList = false;
      }
    }, 300),
    /**
     * 获取自提点列表
     */
    getAjaxSelfTakeList: debounce(async function (logisticsId) {
      this.loadingSelfTakeList = true;
      const params = {
        logistics_id: (logisticsId && [logisticsId]) || [],
        page: 1,
        pageSize: 1000,
      };
      try {
        const { data } = await this.$api.general.deliveryList(params);
        this.selfTakeList = data.data;
        console.log("ajx getAjaxSelfTakeList", data);
      } catch (err) {
        console.log("ajx getAjaxSelfTakeList err", err);
      } finally {
        this.loadingSelfTakeList = false;
      }
    }, 300),
    /**
     * 获取缺货原因列表
     */
    // async getAjaxExplainList() {
    //   try {
    //     const { data } = await getGoodsSheetQCExplainListAll();
    //     this.explainList = data;
    //     console.log("ajax getGoodsSheetQCExplainListAll", data);
    //   } catch (err) {
    //     console.log("ajax getGoodsSheetQCExplainListAll err", err);
    //   }
    // },
    /**
     * 城市仓发生变化
     */
    onChangeCityStore(data) {
      // 重置自提点
      this.selfTakeList = [];
      this.formData.delivery_id = "";
      this.getAjaxSelfTakeList(data);
    },
    /**
     * 查询列表数据
     */
    async ajaxGetTableData() {
      this.loadingData = true;
      // 清空选择项
      this.$refs.refTableList.onClearSelection();
      const params = {
        ...this.formData,
        deduction_time: this.deductionTime ? this.deductionTime.join(",") : "",
      };
      try {
        const { data } = await getQcRefundLackList(params);
        console.log("🆒 data", data);
        const { total, data: list } = data;
        this.total = total;
        this.tableData = list;
      } catch (err) {
        console.log("ajax getQcRefundLackList err", err);
      } finally {
        this.loadingData = false;
        // 模拟数据
        // this.tableData = mock.tableData;
        // this.total = mock.total;
      }
    },
    /**
     * 日期发生了变化
     */
    changeDate(data) {
      // console.log("🆒 changeDate 666", data);
      this.$nextTick(() => {
        this.formData.date = formatDate(data);
      });
    },
    /**
     * 查询表单
     */
    onHandleSearch() {
      this.ajaxGetTableData();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.deliveryList = [];
      this.deductionTime = "";
      this.ajaxGetTableData();
    },
    /**
     * 导出数据
     */
    async onHandleExport() {
      this.disabledExport = true;
      const { username } = JSON.parse(sessionStorage.getItem("userInfo"));
      try {
        const params = {
          ...this.formData,
          user_name: username,
          token: sessionStorage.getItem("token"),
          deduction_time: this.deductionTime
            ? this.deductionTime.join(",")
            : "",
        };
        delete params.page;
        delete params.pageSize;
        await postAfterSaleQcExport(params);
        this.goExportCenter();
      } catch (err) {
        console.log("ajax postAfterSaleQcExport err", err);
      } finally {
        this.disabledExport = false;
      }
    },
    /**
     * 导出后引导弹框
     */
    goExportCenter() {
      // 新窗口打开下载中心
      const routerInfo = this.$router.resolve({
        path: "/export-center",
      });
      window.open(routerInfo.href, "_blank");
      this.disabledExport = false;
    },
    /**
     * 勾选了数据
     */
    onChangeSelect(list) {
      this.selectList = list;
    },
    /**
     * 扣款
     */
    onHandleDeduct() {
      // 防止多次点击
      if (this.loadingCoupon) {
        return;
      }
      if (this.disabledDeduct) {
        this.$message.warning("请选择未扣款的数据~");
        return;
      }
      this.$refs.refDeductDialog.onInitData(this.selectList);
    },
    /**
     * 发券
     */
    async onHandleCoupon() {
      // 防止多次点击
      if (this.loadingCoupon) {
        return;
      }
      if (this.disabledCoupon) {
        this.$message.warning("请选择已扣款且未发券的数据~");
        return;
      }
      const ids = this.selectList.map((item) => item.id);
      const params = {
        entruck_detail_ids: ids,
      };
      try {
        const { data } = await getQcRefundVerifyCoupon(params);
        const msg = `共${this.selectList.length}条缺货数据，发券总金额${data?.coupon_amt}元，是否确认发券？`;
        this.$confirm(msg, "提示", {
          center: true,
          confirmButtonText: "确 认",
          cancelButtonText: "取 消",
          customClass: "jn-confirm-message-style",
          iconClass: "el-icon-warning",
        })
          .then(() => this.ajaxPostQcRefundSendCoupon(params))
          .catch();
      } catch (err) {
        console.log("ajax getQcRefundVerifyCoupon err", err);
      }
    },
    /**
     * 发券接口调用
     */
    async ajaxPostQcRefundSendCoupon(params) {
      this.loadingCoupon = true;
      try {
        await postQcRefundSendCoupon(params);
        // 更新列表
        this.onHandleSearch();
        this.$message.success("发券成功~");
      } catch (err) {
        console.log("ajax postQcRefundSendCoupon err", err);
      } finally {
        this.loadingCoupon = false;
      }
    },
    /**
     * 编辑
     */
    onHandleEdit(row) {
      this.$refs.refEditDialog.onInitData(row);
    },
    /**
     * 修改了每页数据条数
     */
    onHandleSizeChange(val) {
      this.formData.page = 1;
      this.formData.pageSize = val;
      this.ajaxGetTableData();
    },
    /**
     * 修改了页码
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.ajaxGetTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
  }
  .content {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    .el-pagination {
      padding: 10px 20px;
      box-sizing: border-box;
    }
  }
}
/** 下拉选择模态框样式 */
.select-popper-wrap {
  .el-select-dropdown {
    &__item {
      .content {
        max-width: 200px;
        @include ellipsis-lines;
      }
    }
  }
}
/** 操作按钮样式 */
.deduct,
.coupon {
  color: #fff;
  border: none;
}
.deduct {
  background-color: #c42c2c;
  &.disabled {
    background-color: rgba(#c42c2c, 0.3);
  }
}
.coupon {
  background-color: #409eff;
  &.disabled {
    background-color: rgba(#409eff, 0.3);
  }
}
</style>
